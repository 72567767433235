import PartySocket from "partysocket";
import { Application, Assets, Sprite } from "pixi.js";
import { Player } from "./Player";
import type { PlayerData, Receivable } from "../common/data";
import { start } from "./game";

const params = new URLSearchParams(window.location.search);
const model = params.get("model");
const name = params.get("name");

if (model && name) {
  document.getElementById("form")?.remove();
  start(model, name);
}

