import { Application, Spritesheet, AnimatedSprite, Container, Text, TextStyle } from "pixi.js";

export class Player {
  sprite: AnimatedSprite;
  messageText: Text;
  container = new Container();
  animation = "Idle";
  messageExpiresAt = 0;

  constructor(private app: Application, private spritesheet: Spritesheet, public x: number, public y: number, public name: string) {
    this.sprite = new AnimatedSprite(spritesheet.animations[this.animation]);
    this.sprite.animationSpeed = 0.1;
    this.sprite.anchor.set(0.5, 1);
    this.sprite.play();

    const nameStyle = new TextStyle({
      fontSize: 14,
      fontFamily: 'sans-serif',
      align: 'center',
      fill: { color: "white" },
      stroke: { color: "black", width: 2, join: "round" }
    });
    const nameText = new Text({ text: this.name, style: nameStyle });
    nameText.anchor.set(0.5, 0);

    const messageStyle = new TextStyle({
      fontSize: 14,
      fontFamily: 'sans-serif',
      align: 'center',
      fill: { color: "white" },
      stroke: { color: "blue", width: 2, join: "round" }
    });
    this.messageText = new Text({ text: "", style: messageStyle });
    this.messageText.anchor.set(0.5, 4.5);

    this.container.addChild(this.sprite);
    this.container.addChild(this.messageText);
    this.container.addChild(nameText);
    this.container.position.set(x, y);
    this.container.pivot.set(0.5, 1);

    this.app.stage.addChild(this.container);
    this.app.ticker.add((tick) => {
      if (this.messageExpiresAt > 0 && Date.now() > this.messageExpiresAt) {
        this.talk("");
      }
      const dx = this.x - this.container.x;
      const dy = this.y - this.container.y;
      const angle = Math.atan2(dy, dx);
      const distance = Math.sqrt(dx * dx + dy * dy);
      const elapsedTime = tick.deltaTime; // Convert elapsed time to seconds
      const speed = this.animation === "Run" ? 5 : 1;
      const moveDistance = elapsedTime * speed; // Calculate the distance to move based on elapsed time
      if (distance > moveDistance) {
        this.container.x += Math.round(Math.cos(angle) * moveDistance);
        this.container.y += Math.round(Math.sin(angle) * moveDistance);
        // Update player's scale to face left if moving left
        this.sprite.scale.x = dx < 0 ? -1 : 1;
      } else {
        this.setAnimation("Idle");
      }
    });
  }

  setAnimation(animation: string) {
    if (animation === this.animation) return;
    this.animation = animation;
    this.sprite.textures = this.spritesheet.animations[animation];
    this.sprite.animationSpeed = animation === "Run" ? 0.5 : 0.1;
    this.sprite.play();
  }

  walk(x: number, y: number) {
    this.x = x;
    this.y = y;
    const dx = this.x - this.container.x;
    const dy = this.y - this.container.y;
    const distance = Math.sqrt(dx * dx + dy * dy);
    this.setAnimation(distance > 150 ? "Run" : "Walk");
  }

  talk(message: string) {
    this.messageText.text = message;
    if (message.length > 0) this.messageExpiresAt = Date.now() + 10000;
  }

  remove() {
    this.app.stage.removeChild(this.container);
  }
}
