import PartySocket from "partysocket";
import { Application, Assets, Sprite, Spritesheet } from "pixi.js";
import type { PlayerData, Receivable, Sendable } from "../common/data";
import { Player } from "./Player";

async function setupApp() {
    const app = new Application();
    await app.init({ background: "white", width: 480, height: 270 });
    document.getElementById("app")?.appendChild(app.canvas);
    return app;
}

async function setupBackground(app: Application) {
    const texture = await Assets.load("assets/backgrounds/city3/pale.png");
    const sprite = Sprite.from(texture);
    sprite.alpha = 0.5;
    sprite.eventMode = 'static';
    app.stage.addChild(sprite);
    return sprite;
}

const players = new Map<string, Player>();

function actPlayer(id: string, data: PlayerData, app: Application, spritesheets: Record<string, Spritesheet>) {
    const existing = players.get(id);
    if (!existing) {
        const player = new Player(app, spritesheets[data.model], data.x, data.y, data.name);
        players.set(id, player);
        return player;
    }
    existing.walk(data.x, data.y);
    return existing;
}

const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;

export async function start(model: string, name: string) {
    const ws = new PartySocket({
        host: window.location.host,
        room: "example-room",
    });

    const app = await setupApp();
    const background = await setupBackground(app);

    const spritesheets = Object.fromEntries(await Promise.all(['man', 'woman'].map(async (model) => [model, await Assets.load(`assets/spritesheets/${model}.json`)])));

    const initialX = randomInt(64, app.screen.width - 64);
    const initialY = 256 + randomInt(0, app.screen.height - 320);

    ws.addEventListener("message", (event) => {
        const events: Receivable[] = JSON.parse(event.data);
        console.log(events);
        for (let event of events) {
            if ("player" in event && event.player) {
                const player = actPlayer(event.id, event.player, app, spritesheets);
            } else if ("message" in event && event.message !== undefined) {
                const existing = players.get(event.id);
                if (existing) existing.talk(event.message);
            } else {
                const existing = players.get(event.id);
                if (existing) {
                    existing.remove();
                    players.delete(event.id);
                }
            }
        }
    });

    const sendable: Sendable = { player: { x: initialX, y: initialY, name, model } };
    ws.send(JSON.stringify(sendable));

    background.on('pointerup', (event) => {
        const sendable: Sendable = { player: { x: event.globalX, y: event.globalY, name, model } };
        ws.send(JSON.stringify(sendable));
    });

    document.getElementById("message")?.addEventListener("keypress", (event) => {
        if (event.key === "Enter") {
            const message = (event.target as HTMLInputElement).value;
            const sendable: Sendable = { message };
            ws.send(JSON.stringify(sendable));
            (event.target as HTMLInputElement).value = "";
        }
    });
}